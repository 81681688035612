<template>
  <div>
    <vue-header title="拍舌底" isReturn="true"/>
    <camera type="4"/>
  </div>
</template>
<script>
  import vueHeader from "@/components/header";
  import camera from "@/components/camera";
  export default {
    name: "step3",
    components: {
      vueHeader,
      camera
    }
  };
</script>
